import React from "react"
import { graphql, Link } from "gatsby"
import { SiteMetadata } from "../components"
import { Layout } from "../layouts/Layout"
import Img from "gatsby-image"

import { Container, Row, Col} from "react-bootstrap"

export default ({data}) => {
    const post = data.item

    const width = typeof window !== 'undefined' ? window.innerWidth : 1200

    return (
        <Layout >
            <SiteMetadata 
            title={post.breadcrumb != null ? post.breadcrumb : post.title}
            canonical={`/blog/${post.slug}`}
            image={post.img.childImageSharp.fluid.src} 
            description={post.shortdesc !== '' ? post.shortdesc : post.text}
            />
            <Container>
            <Row className="mb-5">
                <Col>
                <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center" itemScope itemType="http://schema.org/BreadcrumbList">
                    <li className="breadcrumb-item"  itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <Link to="/" itemProp="item">
                        <span itemProp="name">Главная</span>
                    </Link>
                    <meta itemProp="position" content="1"/>
                    </li>
                    <li className="breadcrumb-item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <Link to="/blog/" itemProp="item">
                        <span itemProp="name">Блог</span>
                    </Link>
                    <meta itemProp="position" content="2"/>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">{post.breadcrumb != null ? post.breadcrumb : post.title}</li>
                </ol>
                </nav>              
                </Col>
            </Row>
            <Row>  
                {width < 992 ?             
                <Col xs={12} className="d-flex justify-content-center justify-content-lg-start">
                    <h1 className="d-inline-block mb-4 text-center beeright-small">{post.title}</h1>  
                </Col>
                :
                <></>
                }    
                
                <Col className="ml-lg-3" lg={{span: 5, order: 2}}>
                    <Row>
                        <Col xs={12} className="mb-4"><Img fluid={post.img.childImageSharp.fluid} className="rounded" alt={post.title}/></Col>
                    </Row>
                    {width < 992 ? <></> : <AdditionalInfo post={post} />}   
                </Col>
                
                <Col>         
                    {width < 992 ? <></> : <h1 className="d-inline-block mb-4 beeright-small">{post.title}</h1>  }   
                    <div dangerouslySetInnerHTML={{ __html:data.text.html }} />
                    {width < 992 ? <AdditionalInfo post={post} /> : <></>}  
                </Col>                
            </Row>
            </Container>  
        </Layout>
    )
}

const AdditionalInfo = ({post}) => {
    return(
        <>
            {post.recentposts != null ?
                <Row className="mt-5 mt-lg-3">
                    <Col>
                        <Row>
                            <Col xs={12} className="mb-3  text-center text-lg-right"><h3>Полезные статьи</h3></Col>
                            {post.recentposts.map((item, i) => (<RecentPost {...item}/>))}                            
                        </Row> 
                    </Col>
                </Row>
                :
                <></>
            }                    
            
            {post.product != null ?
                <Row>
                    <Col xs={12} className="mb-3  text-center text-lg-right"><h3>Товар в тему</h3></Col>
                    <Col xs={{span: 10, offset: 1}} md={{span: 12, offset: 0}}>
                        <ProductPost {...post.product}/>
                    </Col>
                </Row>
                :
                <></>
            }
        </>
    )
}

const RecentPost = ({img, title, slug}) => {
    return(
        <Col sm={6} className="mb-4">
            <div class="card h-100">
                <Img fluid={img.childImageSharp.fluid} className="card-img-top" alt={title}/>
                <div className="card-body px-2 py-3 text-center">
                    <Link to={`/blog/${slug}/`} className="stretched-link"><h4 className="card-title mb-0">{title}</h4></Link>
                </div>
            </div>
        </Col>
    )
}

const ProductPost = ({name, img, slug, slug_category, price, shortdesc }) => {
    return(
        <div className="border rounded text-center text-md-left">
            <Link to={`/shop/${slug_category}${slug}/`}>
                <Row className="d-flex align-items-center p-2">
                    <Col sm={5}><Img fluid={img.childImageSharp.fluid} alt={name} /></Col>
                    <Col sm={7} className="py-2">
                        <h4>{name}</h4>
                        <div>{price}<span className="small"> руб.</span></div>
                        <div className="small mt-2">{shortdesc}</div>
                    </Col>
                </Row>                
            </Link>            
        </div>        
    )
}

export const query = graphql`
query BlogPageQuery($slug: String!, $markdownPath: String!) {
    item: blogJson(slug: { eq: $slug }) {
        title
        slug
        shortdesc
        breadcrumb
        img {
            childImageSharp {
              fluid(maxWidth: 500, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
        }
        recentposts {
            slug
            title
            img {
                childImageSharp {
                  fluid(maxWidth: 350, maxHeight: 220, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
        }
        product {
            name
            price
            slug
            slug_category
            shortdesc
            img {
                childImageSharp {
                  fluid(maxWidth: 350, maxHeight: 350, cropFocus: NORTH) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
            }
        }
       }
    text: markdownRemark(fileAbsolutePath: {regex: $markdownPath }) {
        html
       }
  }
`